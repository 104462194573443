import React from 'react';
import { Component } from 'react';
import { View } from 'react-native';
import Login, { LoggedIn } from './components/Login';
import { TokenContainer } from './Token';
import UserSpace from './UserSpace';

type StateType = {
  loggedIn: LoggedIn,
  showSplash: boolean
}
export default class App extends Component<{}, StateType> {
  constructor(props: StateType) {
    super(props);
  }
  state = {
    loggedIn: {
      email: "",
      value: false
    },
    showSplash: true
  }
  async componentDidMount() {
    const tokenHandler = new TokenContainer();
    const token = await tokenHandler.getToken();
    if (token !== null && await tokenHandler.checkToken(token)) {
      this.setState({ loggedIn: { email: token.email, value: true } });
    } else if (this.state.loggedIn.value) {
      this.setState({ loggedIn: { email: "", value: false } });
    }
    if (this.state.showSplash) {
      this.setState({ showSplash: false });
    }
  }
  async logout() {
    if (await new TokenContainer().setToken(null)) {
      this.setState({
        loggedIn: {
          email: "",
          value: false
        }
      });
    }
  }
  render() {
    if (this.state.showSplash) {
      return (
        <View />
      );
    }
    if (!this.state.loggedIn.value) {
      return (
        <Login loggedIn={async (loggedIn: LoggedIn) => this.setState({ loggedIn: loggedIn })} />
      );
    }
    return (
      <UserSpace logout={async () => await this.logout()}/>
    );
  }
}


