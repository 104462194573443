// Custom Navigation Drawer / Sidebar with Image and Icon in Menu Options
// https://aboutreact.com/custom-navigation-drawer-sidebar-with-image-and-icon-in-menu-options/

import React, { useState } from 'react';
import {
    SafeAreaView,
    StyleSheet,
    Image,
    View,
} from 'react-native';

import {
    DrawerContentScrollView,
    DrawerItemList,
    DrawerItem,
    DrawerContentComponentProps,
    DrawerContentOptions,
} from '@react-navigation/drawer';
// import { Props } from '@react-navigation/drawer/lib/typescript/src/views/DrawerItem'
import {
    Drawer
} from 'react-native-paper';

interface CustomSidebarMenuProps {
    props: DrawerContentComponentProps<DrawerContentOptions>
    logout: () => Promise<void>
}
const CustomSidebarMenu = ({ props, logout }: CustomSidebarMenuProps) => {
    const BASE_PATH =
        'https://raw.githubusercontent.com/AboutReact/sampleresource/master/';
    const proileImage = 'react_logo.png';
    const [expanded, setExpanded] = useState(false);

    return (
        <SafeAreaView style={{ flex: 1 }}>
            {/*Top Large Image */}
            <Image
                source={{ uri: BASE_PATH + proileImage }}
                style={styles.sideMenuProfileIcon}
            />
            <DrawerContentScrollView {...props}>
                {/* <DrawerItemList {...props} /> */}
                <DrawerItem
                    label="Home"
                    onPress={() => props.navigation.navigate("Home")}
                    focused={props.state.routeNames[props.state.index] === "Home"}
                />
                <DrawerItem
                    label={(props) => {
                        return (
                            <View style={{justifyContent:"space-between", flexDirection:"row"}}>
                                <View>
                                    {"Renting Data"}
                                </View>
                                <View>
                                    {(expanded ? "▾" : "▸")}
                                </View>
                            </View>
                        );
                    }}
                    onPress={async () => setExpanded(!expanded)}
                />
                {expanded && (
                    <Drawer.Section style={{ marginLeft: 5 }}>
                        <DrawerItem
                            label="Renter"
                            onPress={() => {
                                props.navigation.navigate("Renter");
                            }}
                            focused={props.state.routeNames[props.state.index] === "Renter"}
                        />
                        <DrawerItem
                            label="Additional Drivers"
                            onPress={() => {
                                props.navigation.navigate("AdditionalDrivers");
                            }}
                            focused={props.state.routeNames[props.state.index] === "AdditionalDrivers"}
                        />
                        <DrawerItem
                            label="Renting Time"
                            onPress={() => {
                                props.navigation.navigate("RentingTime");
                            }}
                            focused={props.state.routeNames[props.state.index] === "RentingTime"}
                        />
                    </Drawer.Section>
                )}
            </DrawerContentScrollView>
            <Drawer.Section>
                <DrawerItem
                    label="Log out"
                    onPress={async () => await logout()}
                />
            </Drawer.Section>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    sideMenuProfileIcon: {
        resizeMode: 'center',
        width: 100,
        height: 100,
        borderRadius: 100 / 2,
        alignSelf: 'center',
    },
    iconStyle: {
        width: 15,
        height: 15,
        marginHorizontal: 5,
    },
    customItem: {
        padding: 16,
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export default CustomSidebarMenu;
