import Driver from "../classes/Driver";
import { View, StyleSheet, TextInput, TouchableOpacity } from "react-native";
import React, { useState } from "react";
import DatePicker from "./DatePicker";

export default function AdditionalDriver({ driver, removeMe }: { driver: Driver, removeMe: (driver:Driver) => void }) {
    const [name, setName] = useState(driver.name);
    if (name !== driver.name)
        setName(driver.name);
    return (
        <View style={styles.additionalDriverView}>
            <View style={styles.inputView}>
                <TextInput
                    style={styles.inputText}
                    placeholder={"Name"}
                    value={name}
                    placeholderTextColor="#003f5c"
                    onChangeText={text => {
                        driver.name = text;
                        if (text !== name)
                            setName(text);
                    }} />
            </View>
            <View style={styles.inputView}>
                <DatePicker
                    value={driver.birthday}
                    onChange={(selectedDate) => {
                        driver.birthday = selectedDate || driver.birthday;
                    }} />
            </View>
            <TouchableOpacity onPress={() => removeMe(driver)}>
                {"- remove driver"}
            </TouchableOpacity>
        </View>
    );
}

const buttonsMaxWidth = 500;
const styles = StyleSheet.create({

    inputView: {
        width: "80%",
        backgroundColor: "#465881",
        borderRadius: 25,
        height: 50,
        marginBottom: 20,
        justifyContent: "center",
        padding: 20,
        maxWidth: buttonsMaxWidth
    },
    additionalDriverView: {
        backgroundColor: "#00ff00",
        borderRadius: 25,
        marginBottom: 20,
        justifyContent: "center",
        padding: 20
    },
    inputText: {
        height: 50,
        color: "white"
    },
});