import React, { Component } from 'react';
import { StyleSheet, Text, View, TextInput, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { TokenContainer } from '../Token';

export type LoggedIn = {
  email: string,
  value: boolean
}
type PropType = {
  loggedIn: (value: LoggedIn) => Promise<void>;
}
type StateType = {
  email: string,
  password: string,
  recoveryMode: boolean,
  recoveryCode: string,
  error: {
    message: string,
    state: string,
    show: string
  }
}
export default class Login extends Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
  }
  state = {
    email: "",
    password: "",
    recoveryMode: false,
    recoveryCode: "",
    error: {
      message: "",
      state: "success",
      show: "user"
    },
    token: {
      email: "",
      value: ""
    }
  };
  public async signupAction() {
    try {
      let res = await fetch('php/signup.php', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password
        }),
      });
      res.json().then(x => this.setState({ error: x }));
    } catch (e) {
      console.log(e);
    }
  }
  public async loginAction() {
    this.setState({
      error: {
        state: "success",
        message: "logging in ...",
        show: "user"
      }
    });
    if (this.state.recoveryMode) {
      await this.checkRecoveryCode();
      return;
    }
    try {
      let res = await fetch('php/login.php', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password
        }),
      });
      res.json().then(async (x) => {
        this.setState({ error: x });
        if (x.state === "success") {
          try {
            const tokenHandler = new TokenContainer();
            tokenHandler.setToken(x.token);
            const token = await tokenHandler.getToken();
            if (token !== null)
              await this.props.loggedIn({ email: token.email, value: true });
          } catch (e) {
            // saving error
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  private async checkRecoveryCode() {
    try {
      let res = await fetch('php/checkRecoveryCode.php', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password,
          recoveryCode: this.state.recoveryCode
        }),
      });
      res.json().then(x => {
        this.setState({ error: x });
        if (x.state === "success")
          this.setState({ recoveryMode: false });
      });
    } catch (e) {
      console.log(e);
    }
  }
  public async forgotPasswordAction() {
    this.setState({
      error: {
        state: "success",
        message: "sending recovery code",
        show: "user"
      }
    });
    try {
      let res = await fetch('php/forgotPassword.php', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.state.email
        }),
      });
      res.json().then(x => {
        this.setState({ error: x });
        if (x.state === "success" || x.message === "code exists")
          this.setState({ recoveryMode: true });
      });
    } catch (e) {
      console.log(e);
    }
  }
  public async keyPressAction(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    if (e.nativeEvent.key === "Enter") {
      e.preventDefault();
      await this.loginAction();
    }
  }
  private getErrorMessage() {
    if (this.state.error.state === "success") {
      return this.state.error.message;
    }
    return this.state.error.message;
  };
  private getErrorStyle() {
    if (this.state.error.message === "") {
      return styles.errorTextInactive;
    }
    if (this.state.error.state === "success") {
      return styles.successTextActive;
    }
    return styles.errorTextActive;
  };
  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.logo}>Tesla Renting</Text>
        <View style={styles.inputView} >
          <TextInput
            style={styles.inputText}
            placeholder="Email..."
            placeholderTextColor="#003f5c"
            onChangeText={text => this.setState({ email: text })}
            onKeyPress={e => this.keyPressAction(e)} />
        </View>
        <View style={styles.inputView} >
          <TextInput
            secureTextEntry
            style={styles.inputText}
            placeholder="Password..."
            placeholderTextColor="#003f5c"
            onChangeText={text => this.setState({ password: text })}
            onKeyPress={e => this.keyPressAction(e)} />
        </View>
        <View style={styles.errorViewStyle}>
          <Text style={this.getErrorStyle()}>{this.getErrorMessage()}</Text>
        </View>
        <ForgotPasswordUserInput
          recoveryCode={this.state.recoveryCode}
          recoveryMode={this.state.recoveryMode}
          forgotPasswordAction={async () => await this.forgotPasswordAction()}
          changeTextAction={(text) => this.setState({ recoveryCode: text })}
          onKeyPress={e => this.keyPressAction(e)} />
        <TouchableOpacity
          style={styles.loginBtn}
          onPress={() => this.loginAction()}>
          <Text style={styles.loginText}>LOGIN</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => this.signupAction()}>
          <Text style={styles.loginText}>Signup</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

type ForgotPasswordUserInputProps = {
  recoveryCode: string,
  recoveryMode: boolean,
  forgotPasswordAction: () => Promise<void>,
  changeTextAction: (x: string) => void,
  onKeyPress: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => Promise<void>
}

function ForgotPasswordUserInput(props: ForgotPasswordUserInputProps) {
  if (!props.recoveryMode) {
    return (
      <TouchableOpacity>
        <Text
          style={styles.forgot}
          onPress={() => props.forgotPasswordAction()}>
          Forgot Password?</Text>
      </TouchableOpacity>
    );
  }
  return (
    <View style={styles.inputView} >
      <TextInput
        key="myDick"
        style={styles.inputText}
        placeholder="Recovery Code..."
        placeholderTextColor="#003f5c"
        onChangeText={text => props.changeTextAction(text)}
        value={props.recoveryCode}
        onKeyPress={e => props.onKeyPress(e)} />
    </View>
  );
}

const errorTextHeight = 50;
const buttonsMaxWidth = 500;
const styles = StyleSheet.create({
  errorViewStyle: {
    height: errorTextHeight
  },
  errorTextInactive: {
  },
  successTextActive: {
    color: "#FFFFFF",
    backgroundColor: "#00FF00",
    padding: 5
  },
  errorTextActive: {
    color: "#FFFFFF",
    backgroundColor: "#FF0000",
    padding: 5
  },
  container: {
    flex: 1,
    backgroundColor: '#003f5c',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    fontWeight: "bold",
    fontSize: 50,
    color: "#fb5b5a",
    marginBottom: 40
  },
  inputView: {
    width: "80%",
    backgroundColor: "#465881",
    borderRadius: 25,
    height: 50,
    marginBottom: 20,
    justifyContent: "center",
    padding: 20,
    maxWidth: buttonsMaxWidth
  },
  inputText: {
    height: 50,
    color: "white"
  },
  forgot: {
    color: "white",
    fontSize: 11
  },
  loginBtn: {
    width: "80%",
    backgroundColor: "#fb5b5a",
    borderRadius: 25,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    marginBottom: 10,
    maxWidth: buttonsMaxWidth
  },
  loginText: {
    color: "white"
  }
});
