import { DrawerScreenProps } from "@react-navigation/drawer";
import React from "react";
import { useState } from "react";
import { View, StyleSheet, TextInput } from "react-native";
import { Token, TokenContainer } from "../Token";
import AdditionalDrivers from "./AdditionalDrivers";
import RenterComponent from "./RenterComponent";

type RootDrawerParamList = {
  Home: undefined;
};
export default function HomeScreen({ }: DrawerScreenProps<RootDrawerParamList, 'Home'>) {
  const [token, setToken] = useState<Token | null>(null);
  React.useEffect(() => {
    if (token === null)
      new TokenContainer().getToken().then(token => setToken(token));
  });
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      Welcome home!
    </View >
  );
}
const buttonsMaxWidth = 500;
const styles = StyleSheet.create({

  inputView: {
    width: "80%",
    backgroundColor: "#465881",
    borderRadius: 25,
    height: 50,
    marginBottom: 20,
    justifyContent: "center",
    padding: 20,
    maxWidth: buttonsMaxWidth
  },
  inputText: {
    height: 50,
    color: "white"
  },
});