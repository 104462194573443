import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import Driver from "../classes/Driver";
import AdditionalDriver from "./AdditionalDriver";

export default function AdditionalDrivers() {
    const [additionalDrivers, setAdditionalDrivers] = useState<Driver[]>([]);
    const remove = (driver: Driver) => {
        setAdditionalDrivers(additionalDrivers.filter((value, index, array) => value !== driver));
    }
    return (
        <View>
            <TouchableOpacity onPress={() => {
                setAdditionalDrivers([new Driver(), ...additionalDrivers]);
            }}>
                {"+ add additional driver"}
            </TouchableOpacity>
            {additionalDrivers.map((driver => (<AdditionalDriver driver={driver} removeMe={remove} />)))}
        </View>
    );
}