import AsyncStorage from "@react-native-async-storage/async-storage";

export type Token = {
    email: string,
    value: string
}
export class TokenContainer {
    async getToken(): Promise<Token | null> {
        try {
            const value = await AsyncStorage.getItem('@token');
            if (value !== null) {
                const token = JSON.parse(value);
                return {
                    email: token.email,
                    value: token.value
                };
            }
        } catch (e) {
            // error reading value
        }
        return null;
    }
    async setToken(token: Token | null): Promise<boolean> {
        try {
            if (token === null) {
                await AsyncStorage.removeItem('@token');
            } else {
                await AsyncStorage.setItem('@token', JSON.stringify(token));
            }
            return true;
        } catch (e) {
            // saving error
        }
        return false;
    }
    async checkToken(tokenIn: Token | null = null): Promise<boolean> {
        try {
            const token = (tokenIn !== null ? tokenIn : await this.getToken());
            if (token === null) return false;
            let res = await fetch('php/loginByToken.php', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(token),
            });
            const error = await res.json();
            return error.state === "success";
        } catch (e) {
            console.log(e);
        }

        return false;
    }
}