import * as React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import CustomSidebarMenu from './DrawerNavigation/CustomSidebarMenu';
import HomeScreen from './components/HomeScreen';
import RenterComponent from './components/RenterComponent';
import AdditionalDrivers from './components/AdditionalDrivers';
import RentingTimeComponent from './components/RentingTimeComponent';

const Drawer = createDrawerNavigator();

type PropType = {
    logout: () => Promise<void>;
}
export default function UserSpace({ logout }: PropType) {
    return (
        <NavigationContainer>
            <Drawer.Navigator
                initialRouteName="Home"
                drawerContent={(props) => <CustomSidebarMenu props={props} logout={logout} />}
            >
                <Drawer.Screen name="Home" component={HomeScreen} />
                <Drawer.Screen name="Renter" component={RenterComponent} />
                <Drawer.Screen name="AdditionalDrivers" component={AdditionalDrivers} />
                <Drawer.Screen name="RentingTime" component={RentingTimeComponent} />
            </Drawer.Navigator>
        </NavigationContainer>
    );
}