import React from "react";
import { View, StyleSheet, TextInput, TouchableOpacity } from "react-native";

export default function DatePicker(params: DatePickerParams) {
    return (
        <TextInput
            style={styles.inputText}
            placeholder={"Birthday"}
            value={params.value.toString()}
            placeholderTextColor="#003f5c"
            onChangeText={text => {
                params.onChange(new Date(text));
            }} />
    );
}

type DatePickerParams = {
    value: Date;
    onChange: (date: Date) => void;
}

const buttonsMaxWidth = 500;
const styles = StyleSheet.create({

    inputView: {
        width: "80%",
        backgroundColor: "#465881",
        borderRadius: 25,
        height: 50,
        marginBottom: 20,
        justifyContent: "center",
        padding: 20,
        maxWidth: buttonsMaxWidth
    },
    inputText: {
        height: 50,
        color: "white"
    },
});