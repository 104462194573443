import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import RentingTime from "../classes/RentingTime";
import DatePicker from "./DatePicker";

export default function RentingTimeComponent() {
    const [rentingTime, setRentingTime] = useState<RentingTime>(new RentingTime());
    return (
        <View>
            <View style={styles.inputView} >
                <DatePicker
                    value={rentingTime.startDate}
                    onChange={date => rentingTime.startDate = date}
                />
            </View>
            <View style={styles.inputView} >
                <DatePicker
                    value={rentingTime.endDate}
                    onChange={date => rentingTime.endDate = date}
                />
            </View>
        </View>
    )
}

const buttonsMaxWidth = 500;
const styles = StyleSheet.create({

    inputView: {
        width: "80%",
        backgroundColor: "#465881",
        borderRadius: 25,
        height: 50,
        marginBottom: 20,
        justifyContent: "center",
        padding: 20,
        maxWidth: buttonsMaxWidth
    },
});