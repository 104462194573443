import React, { useState } from "react";
import { TextInput, View, StyleSheet, TouchableOpacity } from "react-native";
import Renter from "../classes/Renter";
import DatePicker from "./DatePicker";

export default function RenterComponent() {
    const [renter] = useState<Renter>(new Renter());
    return (
        <View>
            <TextInputTR name="Name" setValue={(text) => {
                renter.name = text;
            }} />
            <TextInputTR name="Address" setValue={(text) => {
                renter.address = text;
            }} />
            <View style={styles.inputView} >
                <DatePicker
                    value={renter.birthday}
                    onChange={date => renter.birthday = date}
                />
            </View>
            <TextInputTR name="Email address" setValue={(text) => {
                renter.emailAddress = text;
            }} />
            <TextInputTR name="Phone Number" setValue={(text) => {
                renter.callNumber = text;
            }} />
            <TextInputTR name="ID Card Number" setValue={(text) => {
                renter.idCardNumber = text;
            }} />
        </View>
    )
}

interface TextInputTRParams {
    name: string;
    setValue: ((text: string) => void);
};
function TextInputTR(params: TextInputTRParams) {
    return (
        <View style={styles.inputView} >
            <TextInput
                style={styles.inputText}
                placeholder={params.name}
                placeholderTextColor="#003f5c"
                onChangeText={text => params.setValue(text)} />
        </View>
    );
}
const buttonsMaxWidth = 500;
const styles = StyleSheet.create({

    inputView: {
        width: "80%",
        backgroundColor: "#465881",
        borderRadius: 25,
        height: 50,
        marginBottom: 20,
        justifyContent: "center",
        padding: 20,
        maxWidth: buttonsMaxWidth
    },
    inputText: {
        height: 50,
        color: "white"
    },
});